import React from 'react';
import { connect } from 'react-redux';
import { DataTable } from 'tyb';
import queryString from 'query-string';
import { downloadLog, packageDownloadLog } from '../../../../redux/modules/tagbatch/tagbatch';
import { formatDate } from 'ucode-utils';
import BackArrow from '@/icons/BackArrow';

@connect(
    state => ({
        downloadLogList: state.tagbatch.downloadLogList,
    }),
    {
        downloadLog: downloadLog.REQUEST,
        packageDownloadLog: packageDownloadLog.REQUEST,
    }
)
class ExtractionCodeHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
        this.columns = [
            {
                key: 'createdIp',
                dataIndex: 'createdIp',
                title: '提取IP'
            },
            {
                key: 'createdTime',
                dataIndex: 'createdTime',
                title: '提取时间',
                render: (val, record) => <div>{formatDate(val)}</div>
            },
            {
                key: 'downloadIp',
                dataIndex: 'downloadIp',
                title: '下载IP'
            },
            {
                key: 'downloadTime',
                dataIndex: 'downloadTime',
                title: '下载时间',
                render: (val, record) => <div>{formatDate(val)}</div>
            },
        ]

        this.batchColumns = [
            {
                key: 'batchs',
                dataIndex: 'batchs',
                title: '文件数'
            },
            {
                key: 'batchId',
                dataIndex: 'batchId',
                title: '批次ID',
            },
        ]
    }


    componentDidMount() {
        const { location } = this.props;
        const query = queryString.parse(location.search);
        const { batchKey, batchName, key } = query || {};
        if (batchKey) {
            this.props.downloadLog(batchKey);
            this.setState({ batchName: batchName })
        }

        if (key) {
            Array.prototype.push.apply(this.columns, this.batchColumns);
            this.setState({ batchName: '码包批量下载历史' });
            this.props.packageDownloadLog(key);
        }

    }

    componentDidUpdate(preProps) {

    }

    getColumns() {
        return this.columns.map(column => {
            return column
        })
    }

    handleRollback = () => {
        const { history } = this.props;
        history.goBack();
    }



    render() {
        const { downloadLogList = {} } = this.props;
        const { content } = downloadLogList;
        return (
            <div className="extraction-file">
                <div className="navbar ">
                    <BackArrow onClick={this.handleRollback} width="20" height="20" style={{ display: 'inline-block', position: 'relative', top: '5px', marginRight: '30px', cursor: 'pointer' }} />
                    <h3 style={{ display: 'inline-block' }}>{this.state.batchName}</h3>
                </div>
                <section className="app-main withtab">
                    <div className="main">
                        <div className="container">
                            <DataTable
                                columns={this.getColumns()}
                                data={content}
                                rowKey="id"
                            />
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}


export default ExtractionCodeHistory;